import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createContext } from 'react';
import { useState } from 'react';

export const ThemeContext = createContext();

const RootComponent = () => {
  const [darkTheme, setDarkTheme] = useState(true);
  return  (
    <ThemeContext.Provider value={{
      darkTheme,
      setDarkTheme
    }}>
      <App />
    </ThemeContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RootComponent/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();