import './App.css';
import w_logo from "./images/w_log.png";
import WLOGO from "./images/T/3.png"
import WLOGO_COVER from "./images/T/3_new.png"
import WLOGO_DARK from "./images/T/3_dark.png"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReCaptcha from "react-google-recaptcha"
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-spinners/PulseLoader"

import {
  FaMoon,
  FaLightbulb,
  FaInfo,
  FaMobile,
  FaGoogle,
  FaWordpress,
  FaWix
} from "react-icons/fa";

import {
  SiBrandfolder,
  SiAltiumdesigner,
  SiGithubactions
} from "react-icons/si";

import { IoMdSunny } from "react-icons/io";
import { 
  FaArrowDown, 
  FaCircleInfo,
  FaSquareFacebook,
  FaSquareTwitter, 
  FaUser,
  FaGears
} from "react-icons/fa6";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '.';
import Typed from 'typed.js';
import { RiMessage3Fill } from "react-icons/ri";

import {
  MdDesignServices,
  MdOutlineArchitecture,
  MdCancel
} from "react-icons/md";

import { CiCircleCheck } from "react-icons/ci";
import {
  IoCodeWorking,
  IoDiamond,
  IoMailOpen
} from "react-icons/io5";

import {
  GoCheckCircle
} from "react-icons/go";

import {
  GiMetalBar,
  GiGoldBar
} from "react-icons/gi";

import {
  SiMaterialdesignicons,
  SiMaterialdesign,
  SiNamebase
} from "react-icons/si";
import { Carousel } from 'react-responsive-carousel';

// importing all the design images here
import Design1 from "./images/important_design1.PNG"
import Design2 from "./images/important_design2.PNG"
import Design3 from "./images/important_design3.png"
import Design4 from "./images/important_design4.jpeg"
import Design5 from "./images/important_design5.png"
import Design6 from "./images/important_design6.jpeg"

import { AiFillInstagram } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { LINKS, offersData } from './constants';

// Adding the MUI components here
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


function App() {
  const themer = useContext(ThemeContext);
  const {darkTheme, setDarkTheme} = themer;
  const typerMain = useRef();
  const [mouseAxis, setMouseAxis] = useState({
    x: 0,
    y: 0
  })

  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const captchaRef = useRef();

  const [formSent , setFormSent] = useState(false);

  const [userInputData, setInputData] = useState({
    first_name: "",
    surname: "",
    email: "",
    message: "",
    company_name: ""
  })

  const [errors, setErrors] = useState({
    fname: null,
    sname: null,
    email: null,
    cname: null,
    mess: null,
    captcha: null
  })

  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is 50 pixels or more
      if (window.scrollY >= 50) {
        setScrolled(true);
        // You can perform additional actions here if needed
      } else {
        setScrolled(false);
        // You can reset any changes made when scrolling back
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMobileChange = (event) => {
    setIsMobile(event.matches);
  }

  useEffect(()=>{
    // checking for the media queries
    const mediaQuery = window.matchMedia('(max-width: 711px)')

    handleMobileChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMobileChange)

    var typer = new Typed(typerMain.current,{
      strings:["Developing", "Designing", "Improving", "Optimizing"],
      typeSpeed:50,
      backSpeed:100,
      loop:true
    })

    // adding a number of circles here first
    return ()=>{
      mediaQuery.removeEventListener('change',handleMobileChange);
      typer.destroy();
    }
  },[])

  const isCaptchaBlank = (token) => {
    if (token === ""){
      return true
    }
    return false
  }

  const setAllErrorsNull = () => {
    setErrors({
      fname: null,
      sname: null,
      cname: null,
      mess: null,
      captcha: null
    })
  }

  const onFormSubmit = async (e) => {
    // checking if form is already sented
    if(formSent) return true;
    setFormSent(true)
    setAllErrorsNull();
    // all errors are now set to null here
    console.log(userInputData,"hellow");
    e.preventDefault()
    // now getting the token of Captcha
    let token = captchaRef.current.getValue();
    if (isCaptchaBlank(token)){
      // now captcha error detected
      setErrors({
        ...errors,
        captcha: "CAPTCHA must be verified to submit form"
      })
      setFormSent(false)
      return false;
    }
    // else now sending the data using function data_sender
    await dataSender(token)
    captchaRef.current.reset()
    setFormSent(false)
  }

  const dataSender = async (
    captcha_token
  ) => {
    // sending the form data to the server backend
    setAllErrorsNull()
    // sending the data through the use of axios here
    console.log(captcha_token);
    await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/contact`,
      {
        first_name: userInputData.first_name,
        surname: userInputData.surname,
        email: userInputData.email,
        company_name: userInputData.company_name,
        message: userInputData.message
      },
      {
        headers:{
          Authorization: captcha_token
        }
      }
    ).then((resp) => {
      console.log(resp.data);
      // now checking if the response is
      let resp_data = resp.data;
      // creating a object with temp errors
      let tempErrors = {
        cname: null,
        fname: null,
        sname: null,
        email: null,
        mess: null
      }
      if(!resp_data.success){
        // errors detected
        // 1. checking if error is by captcha
        if(resp_data.error.captcha_error){
          toast.error(resp_data.error.captcha_error)
        }
        if (resp_data.error.company_name){
          tempErrors.cname = resp_data.error.company_name
        }

        if (resp_data.error.fname){
          tempErrors.fname = resp_data.error.fname
        }

        if (resp_data.error.sname){
          tempErrors.sname = resp_data.error.sname
        }

        if (resp_data.error.email){
          tempErrors.email = resp_data.error.email
        }

        if (resp_data.error.message){
          tempErrors.mess = resp_data.error.message
        }
        // adding a toast saying error in inputs
        toast.error('Invalid details entered in form')
      }
      else{
        toast.success('Congrats form submitted')
      }
      // now changing the state
      setErrors({
        ...tempErrors,
        captcha: null
      })
      console.log(errors);
    }).catch((e)=>{
      alert(e)
    })
  }

  return (
    <div 
      className="App_dark"
      onMouseMove={(e)=>{
        setMouseAxis({
          x: `${e.pageX-20}px`,
          y: `${e.pageY-20}px`
        })
      }}
    >
      <div 
        className='cursor_pointer'
        style={{
          top: mouseAxis.y,
          left: mouseAxis.x
        }}
      ></div>
      <div id='App_cover'>
        <div id='App_cover_wrapper'>
          <div id='App_cover_logo_outline'>
          </div>
          <img 
            id='App_cover_logo' 
            src={WLOGO_COVER}
            alt='COVER_LOGO'
          ></img>
        </div>
      </div>
      <div className='about_patti patti_one'></div>
      <div className='about_patti patti_two'></div>
      <div className='about_patti patti_three'></div>
      <div id='App_wrapper'>
        <div id={!scrolled ? 'navbar' : 'navbar_stick'}>
          <div id='navbar_wrapper'>
            <div 
              id='nav_first'
            >
              <img 
                src={scrolled ? WLOGO_DARK : WLOGO} 
                id='main_logo_dark'
                alt='NAVBAR_LOGO'
              ></img>
            </div>
            <div id='navbar_navi'>
              <div 
                className='navbar_navi_single'
                onClick={()=>{
                  window.location = "#about_section"
                }}
              >
                About
              </div>
              <div 
                className='navbar_navi_single'
                onClick={()=>{
                  window.location = "#services_section"
                }}
              >
                Services
              </div>
              <div 
                className='navbar_navi_single'
                onClick={()=>{
                  window.location = "#our_work"
                }}
              >
                Our work
              </div>
              <div 
                className='navbar_navi_single'
                onClick={()=>{
                  window.location = "#our_offer"
                }}
              >
                Our offer
              </div>
            </div>
            <div id='nav_bar_contacts'>
              {(!isMobile) ? <div
                style={{
                  display:'flex',
                  alignItems:'center',
                  gap:'15px'
                }}
              >
                  <button 
                  id={!scrolled ? 'nav_contact_but_dark' : 'nav_contact_but_dark_sticky'}
                  onClick={()=>{
                    window.location = "#get_in_touch"
                  }}
                >
                  Message Us
                </button>
                {darkTheme ? <IoMdSunny size={35} onClick={()=>{
                  setDarkTheme(false)
                }}></IoMdSunny> : <FaMoon size={35} onClick={()=>{
                  setDarkTheme(true)
                }}></FaMoon>}
              </div> : <div style={{
                display:'flex',
                gap:'15px',
                fontSize:'30px',
                alignItems:'center'
              }}>
                <RiMessage3Fill 
                  onClick={()=>{
                    window.location = LINKS.contact_form_link
                  }}
                ></RiMessage3Fill>
                <FiMenu onClick={()=>setMobileNavOpen(true)}></FiMenu>
                {darkTheme ? <IoMdSunny size={23} onClick={()=>{
                  setDarkTheme(false)
                }}></IoMdSunny> : <FaMoon size={23} onClick={()=>{
                  setDarkTheme(true)
                }}></FaMoon>}
              </div>}
            </div>
          </div>
        </div>
        <div 
          id='welcome'
        >
          <div id='welcome_cont'>
            <div id='welcome_head'>
              <div id='wiz'>WIZIBLE</div>
              <div id='mainer_welcome_head'>
                <span 
                  ref={typerMain}
                  style={{
                    color:'yellow',
                    textShadow:'2px 2px blue'
                  }}
                ></span> websites INCREDIBLY
              </div>
            </div>
            <div id='welcome_sub'>
              we are the agency that got you covetomato for all the types of web related services from designing to backend development to optimization
            </div>
            <div id='welcome_buttons'>
              <button className='welcome_buts'>Message us</button>
              <button className='welcome_buts'>Contact us</button>
            </div>
          </div>
        </div>
        <div id='welcome_end_part'>
          <div id='welcome_end_first'>
            Explore sections
          </div>
          <FaArrowDown size={25} id='welcome_end_last'>
          </FaArrowDown>
        </div>
        <div id='about_section'>
          <div id='about_header'>
            <div id='about_header_text'>
              <div id='about_head'>
                About Us
              </div>
              <div id='about_head_data'>
                Get peace of mind with our structutomato step by step process of working with understanding your actual online representation of 
                your buisness
              </div>
              <div className='about_icons'>
                <FaGears className='a_about_icon'></FaGears>
                <SiAltiumdesigner className='a_about_icon'></SiAltiumdesigner>
                <SiGithubactions className='a_about_icon'></SiGithubactions>
              </div>
            </div>
          </div>
          <div id='about_main'>
            <div id='abot_steps'>
              <div className='abot'>
                <div className='abot_header'>
                  <div className='abot_head'>
                    Understanding
                  </div>
                  <div className='abot_para'>
                    At WizibleWeb, our journey begins with a deep dive into understanding your unique challenges and aspirations. We take the time to unravel the intricacies of your project, ensuring a comprehensive grasp of your needs. By empathizing with your goals and obstacles, we lay the foundation for a tailored and effective solution.
                  </div>
                </div>
                <div className='abot_count'>
                  <div><FaGears></FaGears></div>
                </div>
              </div>
              <div className='abot'>
                <div className='abot_header'>
                  <div className='abot_head'>
                    Finalizing
                  </div>
                  <div className='abot_para'>
                    Once the landscape is clear, we transition into the finalization phase. Here, we meticulously analyze the insights gained during the understanding stage. Collaboration is key as we work closely with you to define the scope, outline the objectives, and refine the details. The result is a concrete plan, a blueprint tailored to address your specific requirements and set the stage for success.
                  </div>
                </div>
                <div className='abot_count'>
                  <div><SiAltiumdesigner></SiAltiumdesigner></div>
                </div>
              </div>
              <div className='abot'>
                <div className='abot_header'>
                  <div className='abot_head'>
                    Taking Action
                  </div>
                  <div className='abot_para'>
                    With a solid foundation in place, we embark on the exciting journey of bringing your vision to life. Taking swift and decisive action, we leverage our expertise in web design and development to turn plans into reality. Every line of code, every design element is crafted with precision and passion. The result is a digital solution that not only meets but exceeds your expectations.
                  </div>
                </div>
                <div className='abot_count'>
                  <div><SiGithubactions></SiGithubactions></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='services_section'>
          <div id='service_section_one'>
            <div id='pre_services_head'>
              SERVICES WE OFFER
            </div>
            <div id='services_head'>
              Our Services
            </div>
            <div id='services_brief'>
              we are a buisness aligned agency we want to help the buisnesses to improve their
              digital representation in term of website we can provide a complete expertise of web-related services
              from designing to development to improvement we will got your covetomato.
            </div>
            <div id='services_list'>
              <div className='service_line'>
                <CiCircleCheck size={25} color='yellow'></CiCircleCheck>
                <div className='service_line_data'>
                  Full website Design from scratch we got your buisness covetomato.
                </div>
              </div>
              <div className='service_line'>
                <CiCircleCheck size={25} color='yellow'></CiCircleCheck>
                <div className='service_line_data'>
                  Improving the website design, performing minimal or heavy changes.
                </div>
              </div>
              <div className='service_line'>
                <CiCircleCheck size={25} color='yellow'></CiCircleCheck>
                <div className='service_line_data'>
                  Full website logic development from scratch according to your buisness requirements.
                </div>
              </div>
              <div className='service_line'>
                <CiCircleCheck size={25} color='yellow'></CiCircleCheck>
                <div className='service_line_data'>
                  Full website logic tomatoesigning, heavy changes on the website logic backend.
                </div>
              </div>
            </div>
          </div>
          <div id='service_section_two'>
            <div id='service_briefs_wrapper'>
              <div className='service_brief_stack'>
                <div 
                  className='service_brief service_brief_pro'
                >
                  <div className='service_brief_logo'>
                    <SiMaterialdesignicons></SiMaterialdesignicons>
                  </div>
                  <div className='service_brief_head'>
                    UI / UX <br></br> Redesigning
                  </div>
                  <div className='service_brief_data'>
                    Revitalize your digital interface with our UI/UX redesign service. We enhance user experience and aesthetics for a captivating and user-friendly design.
                  </div>
                </div>
                <div 
                  className='service_brief'
                >
                  <div className='service_brief_logo'>
                    <FaLightbulb></FaLightbulb>
                  </div>
                  <div className='service_brief_head'>
                    Backend <br></br> Redesigning
                  </div>
                  <div className='service_brief_data'>
                    Optimize your backend with our redesign service. We streamline processes, boost efficiency, and implement cutting-edge technologies for improved functionality.
                  </div>
                </div>
              </div>
              <div className='service_brief_stack'>
                <div 
                  className='service_brief'
                >
                  <div className='service_brief_logo'>
                    <SiMaterialdesign></SiMaterialdesign>
                  </div>
                  <div className='service_brief_head'>
                    UI / UX <br></br> Designing
                  </div>
                  <div className='service_brief_data'>
                    Craft a unique digital presence with our UI/UX design from scratch service. We start with a blank canvas, blending creativity and functionality for a standout user experience.
                  </div>
                </div>
                <div 
                  className='service_brief service_brief_pro'
                >
                  <div className='service_brief_logo'>
                    <MdOutlineArchitecture></MdOutlineArchitecture>
                  </div>
                  <div className='service_brief_head'>
                    Backend <br></br> Designing
                  </div>
                  <div className='service_brief_data'>
                    Build a robust digital foundation with our backend design from scratch service. We create scalable, secure, and efficient systems tailored to your specific requirements.
                  </div>
                </div>
              </div>
              <div className='service_brief_stack'>
                <div 
                  className='service_brief'
                >
                  <div className='service_brief_logo'>
                    <FaWordpress></FaWordpress>
                  </div>
                  <div className='service_brief_head'>
                    WordPress<br></br> Designing
                  </div>
                  <div className='service_brief_data'>
                    Craft a unique digital presence with our UI/UX design from scratch service. We start with a blank canvas, blending creativity and functionality for a standout user experience.
                  </div>
                </div>
                <div 
                  className='service_brief service_brief_pro'
                >
                  <div className='service_brief_logo'>
                    <FaWix></FaWix>
                  </div>
                  <div className='service_brief_head'>
                    WIX Studio<br></br> Designing
                  </div>
                  <div className='service_brief_data'>
                    Build a robust digital foundation with our backend design from scratch service. We create scalable, secure, and efficient systems tailored to your specific requirements.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='our_offer'>
          <div id='offers_higer_data'>
            <div id="offer_higher_prehead">OUR OFFERS</div>
            <div id='offers_higher_head'>How we compare our offers ?</div>
            <div id="offers_higher_para">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, quaerat est possimus asperiores quidem eos alias ab dignissimos! Quia at magni nam, neque esse eum suscipit maxime quae ipsam doloremque. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, quaerat est possimus asperiores quidem eos alias ab dignissimos! Quia at magni nam.
            </div>
            <div className='offers_icons'>
              <GiMetalBar className='a_offers_icon'></GiMetalBar>
              <GiGoldBar className='a_offers_icon'></GiGoldBar>
              <IoDiamond className='a_offers_icon'></IoDiamond>
            </div>
          </div>
          <div id='offers_list'>
            <div className='a_offer'>
              <div className='offer_start_dets'>
                <div className='a_offer_head'>
                  <GiMetalBar className='a_offer_head_icon'></GiMetalBar>
                </div>
                <div className='a_offer_text_head'>
                  Basic
                </div>
                <div className='offer_pricing'>
                  <div className='offer_pricing_pre_head'>
                    STARTS FROM
                  </div>
                  <div className='offer_pricing_data'>
                    600$
                  </div>
                </div>
                <div className='a_offer_data'>
                  <div className='a_offer_data_para'>
                    Our basic package is perfect for those who need a concise yet impactful online presence. With a single page offering, you can showcase up to three sections, ensuring a responsive design with sliders or scrollers. Benefit from server upload and broad browser compatibility.
                  </div>
                  <div className='a_offer_data_givings'>
                    {offersData.basic.map((arr_item, i) =>{
                      return <div className='a_giving'>
                        <GoCheckCircle className="a_giving_logo"></GoCheckCircle>
                        <div className='a_giving_head'>
                          {offersData.basic[i]}
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              </div>
              <div className='offer_end_dets' >
                <button className='a_offer_claim_button'>
                  Contact Now
                </button>
              </div>
            </div>
            <div className='a_offer'>
              <div className='offer_start_dets'>
                <div className='a_offer_head'>
                  <GiGoldBar className='a_offer_head_icon'></GiGoldBar>
                </div>
                <div className='a_offer_text_head'>
                  Standard
                </div>
                <div className='offer_pricing'>
                  <div className='offer_pricing_pre_head'>
                    STARTS FROM
                  </div>
                  <div className='offer_pricing_data'>
                    1300$
                  </div>
                </div>
                <div className='a_offer_data'>
                  <div className='a_offer_data_para'>
                    Step up your digital game with our standard package, providing three pages of dynamic content. Showcase up to five sections with a responsive design, sliders or scrollers, and seamless browser compatibility. Enjoy the convenience of server uploads to keep your content fresh.
                  </div>
                  <div className='a_offer_data_givings'>
                    {offersData.medium.map((v, i) => {
                      return <div className='a_giving'>
                        <GoCheckCircle className="a_giving_logo"></GoCheckCircle>
                        <div className='a_giving_head'>
                          {offersData.medium[i]}
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              </div>
              <div className='offer_end_dets'>
                <button className='a_offer_claim_button'>
                  Contact Now
                </button>
              </div>
            </div>
            <div className='a_offer'>
              <div className='offer_start_dets'>
                <div className='a_offer_head'>
                  <IoDiamond className='a_offer_head_icon'></IoDiamond>
                </div>
                <div className='a_offer_text_head'>
                  Premium
                </div>
                <div className='offer_pricing'>
                  <div className='offer_pricing_pre_head'>
                    STARTS FROM
                  </div>
                  <div className='offer_pricing_data'>
                    2200$
                  </div>
                </div>
                <div className='a_offer_data'>
                  <div className='a_offer_data_para'>
                    For those seeking an extensive online footprint, our premium package delivers seven pages of immersive content. Explore up to six sections with a responsive design, engaging sliders or scrollers, and efficient server upload capabilities.
                  </div>
                  <div className='a_offer_data_givings'>
                    {offersData.premium.map((v, i) => {
                      return <div className='a_giving'>
                        <GoCheckCircle className='a_giving_logo'></GoCheckCircle>
                        <div className='a_giving_head'>
                          {v}
                        </div>
                      </div>
                    })}
                  </div>
                </div>
              </div>
              <div className='offer_end_dets'>
                <button className='a_offer_claim_button'>
                  Contact Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id='our_work'>
          <div id='our_work_section_one'>
            <div id='our_work_head'>
              Our Work
            </div>
            <div id='our_work_subline'>
              the experiences we have created for the people is like awesome ones you should must see them
              at our gallary below what type of websites we have delievetomato till now.
            </div>
          </div>
          <div id='our_work_section_three'>
            <div className='work_vertical_wrapper'>
              <div className='work_vertical'>
                <div className='work_vertical_img'>
                  <img 
                    src={Design5}
                    alt='DESIGN 5'
                  ></img>
                </div>
                <div className='our_work_texts'>
                  <div className='work_vertical_head'>
                    Wizible Web
                  </div>
                  <div className='work_vertical_para'>
                    Immerse your audience in a world of captivating visual storytelling. Our portfolio showcases designs that not only speak but resonate with your brand's unique narrative. Experience the perfect blend of elegance and intuitiveness.
                  </div>
                  <button 
                    className='design_but'
                    onClick={()=>{
                      window.open("https://wizibleweb.com")
                    }}
                  >
                    Open site
                  </button>
                </div>
              </div>
              <div className='work_vertical'>
                <div className='work_vertical_img'>
                  <img 
                    src={Design6}
                    alt='DESIGN_SIX'
                  ></img>
                </div>
                <div className='our_work_texts'>
                  <div className='work_vertical_head'>
                    Salon S & A
                  </div>
                  <div className='work_vertical_para'>
                    Immerse your audience in a world of captivating visual storytelling. Our portfolio showcases designs that not only speak but resonate with your brand's unique narrative. Experience the perfect blend of elegance and intuitiveness.
                  </div>
                  <button 
                    className='design_but'
                    onClick={()=>{
                      window.open("https://candid-alpaca-6ebacc.netlify.app")
                    }}
                  >
                    Open site
                  </button>
                </div>
              </div>
              <div className='work_vertical'>
                <div className='work_vertical_img'>
                  <img 
                    src={Design1}
                    alt='DESIGN_ONE'
                  ></img>
                </div>
                <div className='our_work_texts'>
                  <div className='work_vertical_head'>
                    Topic Listing
                  </div>
                  <div className='work_vertical_para'>
                    Our web designs prioritize user experience, ensuring an interface that is both engaging and easy to navigate. Explore topics effortlessly with a design that puts your audience first. Foster easy topic discovery with our thoughtfully designed interfaces. Our websites empower users to explore and engage with content effortlessly.
                  </div>
                  <button 
                    className='design_but'
                    onClick={()=>{
                      window.open("https://tanishq-prajapati.github.io/design1/")
                    }}
                  >
                    Open site
                  </button>
                </div>
              </div>
            </div>
            <div className='work_vertical_wrapper'>
              <div className='work_vertical'>
                <div className='work_vertical_img'>
                  <img 
                    src={Design3}
                    alt='DESIGN_THREE'
                  ></img>
                </div>
                <div className='our_work_texts'>
                  <div className='work_vertical_head'>
                    Portfolio
                  </div>
                  <div className='work_vertical_para'>
                    Immerse your audience in a world of captivating visual storytelling. Our portfolio showcases designs that not only speak but resonate with your brand's unique narrative. Experience the perfect blend of elegance and intuitiveness.
                  </div>
                  <button 
                    className='design_but'
                    onClick={()=>{
                      window.open("https://tanishq-prajapati.github.io/design3/")
                    }}
                  >
                    Open site
                  </button>
                </div>
              </div>
              <div className='work_vertical'>
                <div className='work_vertical_img'>
                  <img 
                    src={Design4}
                    alt='DESIGN_FOUR'
                  ></img>
                </div>
                <div className='our_work_texts'>
                  <div className='work_vertical_head'>
                    Topic Listing
                  </div>
                  <div className='work_vertical_para'>
                    Our web designs prioritize user experience, ensuring an interface that is both engaging and easy to navigate. Explore topics effortlessly with a design that puts your audience first. Foster easy topic discovery with our thoughtfully designed interfaces. Our websites empower users to explore and engage with content effortlessly.
                  </div>
                  <button 
                    className='design_but'
                    onClick={()=>{
                      window.open("https://webdevtanishq.agency")
                    }}
                  >
                    Open site
                  </button>
                </div>
              </div>
              <div className='work_vertical'>
                <div className='work_vertical_img'>
                 <img 
                  src={Design2}
                  alt='DESIGN_TWO'
                ></img>
                </div>
                <div className='our_work_texts'>
                  <div className='work_vertical_head'>
                    Course Selling
                  </div>
                  <div className='work_vertical_para'>
                    Experience the simplicity of our web design, where learners effortlessly navigate through courses, making education accessible and enjoyable. Our design prioritizes an engaging visual hierarchy.
                  </div>
                  <button 
                    className='design_but'
                    onClick={()=>{
                      window.open("https://tanishq-prajapati.github.io/design2/")
                    }}
                  >
                    Open site
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id='cara_work_header'>
            <div id='cara_work_head'>
              Showcasing Our Design
            </div>
            <div id='cara_work_para'>
              Here i am showcasing you the designs below in the
              form of images in a caraousel we have created many professional designs plus
              having a very high experience in developing and designing websites
            </div>
          </div>
          <div id='our_work_section_two'>
            <Carousel width={'100%'} className='cara'>
              <div>
                <img 
                  src={Design1}
                  alt='DESIGN_ONE'
                ></img>
              </div>
              <div>
                <img 
                  src={Design2}
                  alt='DESIGN_TWO'
                ></img>
              </div>
              <div>
                <img 
                  src={Design3}
                  alt='DESIGN_THREE'
                ></img>
              </div>
              <div>
                <img 
                  src={Design4}
                  alt='DESIGN_FOUR'
                ></img>
              </div>
              <div>
                <img 
                  src={Design5}
                  alt='DESIGN_FIVE'
                ></img>
              </div>
              <div>
                <img 
                  src={Design6}
                  alt='DESIGN_ONE'
                ></img>
              </div>
            </Carousel>
          </div>
        </div>
        <div id='get_in_touch'>
          <div id='touch_header'>
            <div id='touch_header_first'>
              Contact Us
            </div>
            <div id='touch_header_para'>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti odit, itaque earum suscipit enim mollitia in consectetur magnam qui? Eum repellendus voluptatem delectus eaque maiores alias in consequuntur dolorum
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti odit, itaque earum suscipit enim mollitia in consectetur
            </div>
            <div className='about_icons'>
              <IoMailOpen className='a_about_icon'></IoMailOpen>
              <SiAltiumdesigner className='a_about_icon'></SiAltiumdesigner>
              <FaMobile className='a_about_icon'></FaMobile>
            </div>
          </div>
          <div id='form_area'>
            <div id='contact_alternatives'>
              <div id='contact_alt_main'>
                <div className='contact_alt_wrapper'>
                  <div className='contact_alt_head'>
                    <div className='contact_alt_in_head'>
                      <FaMobile></FaMobile>
                    </div>
                    Call Us
                  </div>
                  <div className='contact_alt_para'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti odit, itaque earum suscipit enim mollitia in consectetur magnam qui? Eum repellendus voluptatem delectus eaque maiores alias in consequuntur dolorum
                  </div>
                  <button className='contact_alt_button'>
                    call here
                  </button>
                </div>
                <div className='contact_alt_wrapper'>
                  <div className='contact_alt_head'>
                    <div className='contact_alt_in_head'>
                      <IoMailOpen></IoMailOpen>
                    </div>
                    Mail Us
                  </div>
                  <div className='contact_alt_para'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti odit, itaque earum suscipit enim mollitia in consectetur magnam qui? Eum repellendus voluptatem delectus eaque maiores alias in consequuntur dolorum
                  </div>
                  <button className='contact_alt_button'>
                    Mail here
                  </button>
                </div>
                <div className='contact_alt_wrapper'>
                  <div className='contact_alt_head'>
                    <div className='contact_alt_in_head'>
                      <FaGoogle></FaGoogle>
                    </div>
                    Google Form
                  </div>
                  <div className='contact_alt_para'>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti odit, itaque earum suscipit enim mollitia in consectetur magnam qui? Eum repellendus voluptatem delectus eaque maiores alias in consequuntur dolorum
                  </div>
                  <button className='contact_alt_button'>
                    Go to Forms
                  </button>
                </div>
              </div>
            </div>
            <form 
              onSubmit={onFormSubmit}
              id='form'
            >
              <div>
                <div id='message_us'>
                  Message Us
                </div>
                <div id='message_us_pre'>
                  fill this form and we wil ltake care of rest of things for you
                </div>
              </div>
              <div className='two_inputs'>
                <div className='input_parent_wrapper'>
                  <div 
                    className='input_wrapper'
                    style={{
                      borderColor:(errors.fname ? 'tomato' : 'white')
                    }}
                  >
                    <div className='input_wrapper_log'>
                      <FaUser color={errors.fname ? 'tomato' : 'white'}></FaUser>
                    </div>
                    <input
                      placeholder='First Name'
                      type='text'
                      onInput={(e)=>{
                        setErrors({
                          ...errors,
                          fname: null
                        })
                        setInputData({
                          ...userInputData,
                          first_name: e.currentTarget.value
                        })
                      }}
                    ></input>
                  </div>
                  {errors.fname && <div className='errors'>{errors.fname}</div>}
                </div>
                <div className='input_parent_wrapper'>
                  <div 
                    className='input_wrapper'
                    style={{
                      borderColor:(errors.sname ? 'tomato' : 'white')
                    }}
                  >
                    <div className='input_wrapper_log'>
                      <SiNamebase color={errors.sname ? 'tomato' : 'white'}></SiNamebase>
                    </div>
                    <input 
                      type='text'
                      placeholder='Surname'
                      onInput={(e)=>{
                        setErrors({
                          ...errors,
                          sname: null
                        })
                        setInputData({
                          ...userInputData,
                          surname:e.currentTarget.value
                        })
                      }}
                    ></input>
                  </div>
                  {errors.sname && <div className='errors'>{errors.sname}</div>}
                </div>
              </div>
              <div className='two_inputs'>
                <div className='input_parent_wrapper'>
                  <div 
                    className='input_wrapper'
                    style={{
                      borderColor:(errors.email ? 'tomato' : 'white')
                    }}
                  >
                    <div className='input_wrapper_log'>
                      <IoMailOpen color={errors.email ? 'tomato' : 'white'}></IoMailOpen>
                    </div>
                    <input
                      placeholder='Email here'
                      type='email'
                      onInput={(e)=>{
                        setErrors({
                          ...errors,
                          email: null
                        })
                        setInputData({
                          ...userInputData,
                          email: e.currentTarget.value
                        })
                      }}
                    ></input>
                  </div>
                  {errors.email && <div className='errors'>{errors.email}</div>}
                </div>
                <div className='input_parent_wrapper'>
                  <div 
                    className='input_wrapper'
                    style={{
                      borderColor:(errors.cname ? 'tomato' : 'white')
                    }}
                  >
                    <div className='input_wrapper_log'>
                      <SiBrandfolder color={errors.cname ? 'tomato' : 'white'}></SiBrandfolder>
                    </div>
                    <input 
                      type='text'
                      placeholder='Company name'
                      onInput={(e)=>{
                        setErrors({
                          ...errors,
                          cname: null
                        })
                        setInputData({
                          ...userInputData,
                          company_name: e.currentTarget.value
                        })
                      }}
                    ></input>
                  </div>
                  {errors.cname && <div className='errors'>{errors.cname}</div>}
                </div>
              </div>
              <div className='two_inputs'>
                <textarea 
                  id='text_area'
                  placeholder='Enter message here'
                  style={{
                    borderColor:(errors.mess ? 'tomato' : 'white'),
                  }}
                  onInput={(e)=>{
                    setErrors({
                      ...errors,
                      mess: null
                    })
                    setInputData({
                      ...userInputData,
                      message:e.currentTarget.value
                    })
                  }}
                ></textarea>
              </div>
              <div>
                <ReCaptcha
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                ></ReCaptcha>
                {errors.captcha && <div style={{
                  color:'tomato',
                  fontWeight:'600',
                  marginTop:'10px'
                }}>{errors.captcha}</div>}
              </div>
              <button 
                type='submit'
                id='form_submit_button'
              >
                {!formSent ? "SUBMIT" : <Loader color='black' size={10} ></Loader>}
              </button>
            </form>
          </div>
        </div> 
        <div id={isMobileNavOpen ? 'navigation_panel' : 'navigation_panel_sec'}>
          <div id='nav_panel'>
            <div id='nav_list_one'>
              <div 
                className='nav_single_mobile'
                onClick={()=>{
                  window.location = "#about_section"
                }}
              >
                About
              </div>
              <div 
                className='nav_single_mobile'
                onClick={()=>{
                  window.location = "#services_section"
                }}
              >
                Services
              </div>
              <div 
                className='nav_single_mobile'
                onClick={()=>{
                  window.location = "#our_work"
                }}
              >
                Our Work
              </div>
              <div 
                className='nav_single_mobile'
                onClick={()=>{
                  window.location = "#our_offer"
                }}
              >
                Our Offers
              </div>
              <button id='mobile_nav_button'>
                Message us
              </button>
            </div>
            <div id='nav_list_two'>
                <MdCancel 
                  size={40} 
                  style={{
                    marginLeft:'20px'
                  }} id='cancel_button'
                  onClick={()=>{
                    setMobileNavOpen(false);
                  }}
                ></MdCancel>
            </div>
          </div>
        </div>
      </div>
      <div id='footer_parent'>
        <footer id='footer'>
          <div id='footer_one'>
            <img 
              id='footer_logo'
              src={w_logo}
              alt='FOOTER_LOGO'
            ></img>
            <div id='footer_label'>WIZIBLEWEB</div>
          </div>
          <div id='footer_two'>
            <div id='list_of_navs'>
              <div className='one_foot_nav'>
                Home
              </div>
              <div className='one_foot_nav'>
                About
              </div>
              <div className='one_foot_nav'>
                Services
              </div>
              <div className='one_foot_nav'>
                Our Work
              </div>
            </div>
          </div>
          <div id='footer_four'>
            <div id='list_of_navs_four'>
              <div className='one_foot_nav_four'>
                Get In Touch
              </div>
              <div className='one_foot_nav_four'>
                Message Now
              </div>
            </div>
          </div>
          <div id='footer_three'>
            <AiFillInstagram className='footer_labs'></AiFillInstagram>
            <FaSquareFacebook className='footer_labs'></FaSquareFacebook>
            <FaSquareTwitter className='footer_labs'></FaSquareTwitter>
          </div>
        </footer>
        <div id='footer_end'>
          <div id='footer_end_first'>
            @ 2023, WIZIBLEWEB PRIVATE LIMITED
          </div>
          <div id='footer_end_last'>
            ALL RIGHTS RESERVED
          </div>
        </div>
      </div>
      <div id={!darkTheme ? 'light_mode_start' : 'light_mode_ends'} style={{
        mixBlendMode:'difference',
        width:'100%',
        height:'100%',
        backgroundColor:'white',
        opacity:'0.9',
        position:'fixed',
        zIndex:'300',
        pointerEvents:'none'
      }}>
      </div>
      {/* {isMobile ?
      <div
        id={scrolled ? 'welcome_top_sec_mobile' : 'welcome_top_sec_mobile_ends'}>
        {scrolled && <div style={{
          display:'flex',
          justifyContent:'space-between',
          paddingRight:'30px'
        }}>
          <div style={{
            display:'flex',
            gap:'20px'
          }}>
            <AiFillInstagram size={25}></AiFillInstagram>
            <FaSquareFacebook size={25}></FaSquareFacebook>
            <FaSquareTwitter size={25}></FaSquareTwitter>
          </div>
          <div style={{
            marginRight:'30px',
            fontFamily:'Poppins',
            textTransform:'uppercase',
            letterSpacing:'1px'
          }}>
            Call us on +91 9343201630
          </div>
        </div>}
    </div>
      :
      <div
        id={!scrolled ? 'welcome_top' : 'welcome_top_sec'}>
          {scrolled && <div style={{
            display:'flex',
            justifyContent:'space-between',
            paddingRight:'30px'
          }}>
            <div style={{
              display:'flex',
              gap:'20px'
            }}>
              <AiFillInstagram size={25}></AiFillInstagram>
              <FaSquareFacebook size={25}></FaSquareFacebook>
              <FaSquareTwitter size={25}></FaSquareTwitter>
            </div>
            <div style={{
              marginRight:'30px',
              fontFamily:'Poppins',
              textTransform:'uppercase',
              letterSpacing:'1px'
            }}>
              Call us on +91 9343201630
            </div>
          </div>}
      </div>} */}
      <ToastContainer
        position='bottom-right'
        hideProgressBar={false}
        closeOnClick
        theme='light'
      ></ToastContainer>
    </div>
  );
}

export default App;
