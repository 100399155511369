const LINKS = {
    contact_form_link: "https://docs.google.com/forms/d/e/1FAIpQLSfFIAAN7jcNbNYlRw0hnboTHA2UNP1Nhk_GGCOtJl-tSa_Qkg/viewform?usp=sf_link"
}

const offersData = {
    basic:[
        "Customized responsive website design (up to 5 pages)",
        "Mobile-friendly design for optimal user experience",
        "Integration of essential features (contact form, social media links)",
        "Basic SEO optimization for improved search engine visibility",
        "2 rounds of revisions",
        "Delivery within 2 weeks",
        "Monthly Maintenance cost starts from $100",
    ],
    medium:[
        "Professional e-commerce website design (up to 10 pages)",
        "User-friendly navigation and product showcase",
        "Integration of a secure payment gateway",
        "Responsive design for seamless shopping experience on all devices",
        "Product management system for easy updates",
        "Basic SEO optimization for product pages",
        "3 rounds of revisions",
        "Delivery within 4 weeks",
        "Price: $1,200",
        "Monthly Maintenance cost starts from $150"
    ],
    premium:[
        "Customized and visually stunning website design (up to 15 pages)",
        "Advanced features such as animations, parallax scrolling, and interactive elements",
        "Mobile optimization and cross-browser compatibility",
        "Integration of content management system (CMS) for easy updates",
        "Monthly website maintenance, including updates and backups",
        "Enhanced SEO optimization for better online visibility",
        "5 rounds of revisions",
        "Priority support for ongoing maintenance",
        "Delivery within 6 weeks",
        "Price: $2,500 (includes the first three months of maintenance)",
        "Monthly Maintenance cost starting from $200",
    ]
}

export {
    LINKS,
    offersData
}